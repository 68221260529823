.servicesWrapper {
  position: relative;
  width: 100%;
}

.servicesButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  background: var(--white);
  border: 1px solid var(--light-gray);
  width: 100%;
}

.buttonText {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  width: fit-content;
  font-family: var(--font-family);
}

.buttonImage {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.buttonImage svg {
  fill: var(--black);
}

.buttonImage:hover svg {
  fill: var(--gray);
}

.rotate {
  transform: rotate(180deg);
}

.servicesList {
  position: absolute;
  bottom: 0;
  transform: translate(0px, 100%);
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 8;
  width: 100%;
  max-width: 322px;
  overflow: auto;
  background: var(--white);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}

.servicesList::-webkit-scrollbar {
  width: 0;
}

.servicesSelected {
  margin-top: 16px;
}

