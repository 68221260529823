.container {
  width: 100%;
  padding: 89px 46px 48px 44px;
  background: var(--bg-gray);
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 135px 40px 48px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 88px 48px 48px;
  }
}

@media screen and (max-width: 475px) {
  .container {
    padding: 65px 12px 32px;
  }
}
