*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

img {
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 700;
}

p {
  line-height: 24px;
  margin: 0;
}

button {
  cursor: pointer;
  border: none;
  background: none;
  color: inherit;
  outline: none;
  font-weight: 600;
  padding: 0;
}

input {
  outline: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
}

iframe {
  border: none;
  vertical-align: middle;
}
