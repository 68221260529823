.selectedService {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
}

.selectedService > span > span {
  color: var(--gray);
}

.selectedService > button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px 0 12px;
}

.selectedService svg {
  fill: var(--light-gray);
}

.selectedService:hover {
  color: var(--gray);
}

.selectedService svg:hover {
  fill: var(--gray);
}
