.main {
  display: grid;
  grid-template-columns: 322px 1fr;
  grid-template-rows: auto auto;
  grid-auto-rows: auto;
  gap: 24px 20px;
  position: relative;
}

.mapContainer {
  width: 100%;
  grid-column: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 500px;
  position: sticky;
  top: calc(50% - 35vh);
}

.mainTitle {
  font-weight: 300;
  font-size: 60px;
  line-height: 72px;
  margin-bottom: 64px;
}

.title {
  font-weight: 300;
  font-size: 48px;
  line-height: 54px;
  margin-bottom: 32px;
}

@media screen and (max-width: 1256px) {
  .mainTitle {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 48px;
  }

  .title {
    font-size: 28px;
    line-height: 36px;
  }
}

@media screen and (max-width: 1052px) {
  .mainTitle {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .main {
    grid-template-columns: 304px 1fr;
    gap: 24px 16px;
  }
}

@media screen and (max-width: 960px) {
  .main {
    grid-template-columns: 1fr;
  }

  .mapContainer {
    grid-column: 1;
    grid-row-start: 2;
    grid-row-end: 2;
    position: unset;
    top: unset;
    height: 300px;
  }
}

@media screen and (max-width: 475px) {
  .mainTitle {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 32px;
  }

  .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
}
