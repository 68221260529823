.clusterer {
  width: 100%;
  height: 100%;
  background-color: var(--pin-selected);
  border: 2px solid var(--white);
  color: var(--black);
  font-weight: 700;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

ymaps[class*="popup"] {
  padding: 0px;
  background: var(--white);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border: none;
  top: 1px;
  width: calc(100% + 40px);
}

ymaps[class*="suggest-item-0"] {
  margin-top: 0;
}

ymaps[class*="search__suggest-item"] {
  padding: 8px 16px;
  background: var(--white);
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  font-family: var(--font-family);
}

ymaps[class*="search__suggest-item"]:hover {
  background: var(--bg-gray);
}
