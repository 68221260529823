.btn {
  position: relative;
  overflow: hidden;
  transition: color .3s,border-color .3s;
  white-space: normal;
  background-color: transparent;
  padding: 14px 47px;
  display: flex;
  width: 100%;
  max-width: 225px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-decoration: none;
}

.btn span {
  position: relative;
  z-index: 3;
}

.btn::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 1;
    width: 118%;
    height: 100%;
    transform-origin: 200% 0;
    left: 0;
    transform: skew(-23deg) translateX(-101%);
    transition: transform .55s cubic-bezier(0,.545,.19,1),-webkit-transform .55s cubic-bezier(0,.545,.19,1);
}

.black-button {
  background-color: var(--black);
  border: 1px solid var(--black);
  color: var(--white);
}

.black-button::after {
  background-color: var(--gray);
}

.black-button:hover {
  border: 1px solid var(--gray);
}

.black-button:hover::after {
  transform: skew(-23deg) translateX(0px);
  transition: transform .55s cubic-bezier(0,.545,.19,1),-webkit-transform .55s cubic-bezier(0,.545,.19,1);
}

.white-button {
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
}

.white-button::after {
  background-color: var(--yellow);
}

.white-button:hover {
  border: 1px solid var(--yellow);
}

.white-button:hover::after {
  transform: skew(-23deg) translateX(0px);
  transition: transform .55s cubic-bezier(0,.545,.19,1),-webkit-transform .55s cubic-bezier(0,.545,.19,1);
}
