.radioLabel {
  display: flex;
  align-items: center;
  gap: 8px;
}

.radioLabel input[type=radio] {
  display: none;
}

.radioLabel span {
  display: inline-block;
  cursor: pointer;
}

.radioLabel div {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  background: var(--white);
  border: 1px solid var(--black);
  border-radius: 50%;
  overflow: hidden;
}

.radioLabel div::after {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  background: var(--white);
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.4s;
}

.radioLabel input[type=radio]:checked + div::after {
  background:var(--black);
}
