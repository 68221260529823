.searchWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.gpsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: var(--black);
}

.gpsButton:hover {
  background: var(--gray);
}

.gpsImage {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBarWrapper {
  height: 40px;
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
  border-right: 1px solid var(--light-gray);
  background: var(--white);
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  position: relative;
}

.inputWrapper {
  width: calc(100% - 40px);
  position: relative;
  height: 100%;
}

.searchInput {
  padding: 6px 36px 6px 16px;
  width: 100%;
  background: var(--white);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  border: none;
  height: 100%;
}

.searchInput:-internal-autofill-selected {
  background-color: var(--white) !important;
}

.searchInput::placeholder {
  color: var(--dark-gray);
}

.removeText {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeText svg {
  fill: var(--light-gray);
}

.removeText:hover svg {
  fill: var(--gray);
}

.listButton {
  width: 40px;
  height: 38px;
  border-left: 1px solid var(--light-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
}

.optionsButtonImage {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  transition: .2s transform;
}

.optionsButtonImage svg {
  fill: var(--black);
}

.optionsButtonImage:hover svg {
  fill: var(--gray);
}

.rotate {
  transform: rotate(180deg);
}

.resultsList {
  position: absolute;
  bottom: -1px;
  transform: translate(0px, 100%);
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 8;
  width: 100%;
  /* max-width: 280px; */
  overflow: auto;
  max-height: 200px;
  background: var(--white);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}

.resultsList::-webkit-scrollbar {
  width: 0;
}

.resultCard {
  padding: 8px 16px;
  background: var(--white);
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  text-overflow: ellipsis;
}

.resultCard:hover {
  background: var(--bg-gray);
}

.listCard {
  padding: 8px 16px;
  background: var(--white);
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  cursor: pointer;
}

.listCard span {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.listCard:hover {
  background: var(--bg-gray);
}
