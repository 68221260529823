.loader {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid var(--white);
  border-bottom-color: transparent;
  box-sizing: border-box;
  animation: spin 1.3s linear infinite;
  background: none;

  /* &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${(props) => (props.bg ? `var(--${props.bg})` : 'var(--white)')};
  } */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
