.cardWrapper {
  width: 100%;
  max-width: 322px;
  flex-direction: column;
  display: flex;
  gap: 16px;
  padding-top: 24px;
  border-top: 1px solid var(--light-gray);
  cursor: pointer;
}

.cardHeader {
  display: flex;
  gap: 16px;
}

.pinImage {
  width: 26px;
}

.pinImage svg {
  vertical-align: middle;
  width: 100%;
}

.nameBlock {
  padding-top: 4px;
  flex-direction: column;
  display: flex;
}

.serviceName {
  font-weight: 700;
}

.nameExtra {
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-gray);
}

.informationBlock {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.informationBlock .infoText {
  padding-top: 2px;
}

.informationItem {
  display: flex;
  gap: 8px;
}

.informationImage {
  width: 24px;
  max-width: 322px;
}

.informationImage svg {
  fill: var(--gray);
  vertical-align: middle;
}

.infoText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray);
  white-space: pre-wrap;
}

.infoLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray);
}

.infoLink:hover {
  color: var(--black);
}

.serviseText {
  font-size: 14px;
  line-height: 20px;
}

.servivesList {
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 10px;
}

.servivesList .informationItem {
  align-items: center;
}

@media screen and (max-width: 960px) {
  .cardWrapper {
    max-width: 960px;
  }
}

/* .radioTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}

.radioBoxes {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
} */
