.checkboxButtonLabel {
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  background: var(--white);
}

.checkboxButtonLabel:hover {
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  background: var(--bg-gray);
}

.checkboxButtonLabel input[type=checkbox] {
  display: none;
}

.checkboxButtonLabel span {
  display: inline-block;
  cursor: pointer;
}

.checkboxButtonLabel div {
  display: inline-block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  background-color: var(--white);
  background-image: url('./../../../../public/image/check.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  overflow: hidden;
  transition: 0.3s;
}

.checkboxButtonLabel:hover div {
  border: 1px solid var(--black);
}

.checkboxButtonLabel input[type=checkbox]:checked + div {
  background-color: var(--black);
}

.checkboxButtonLabel:hover input[type=checkbox]:checked + div {
  background-color: var(--gray);
  border: 1px solid var(--gray);
}

.buttonText span {
  color: var(--light-gray);
}
